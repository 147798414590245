import { createQuote, sendUtm } from "@/shared/http"
import { Utms } from "@/shared/types/global-store"
import { hasUtm } from "@/shared/utils/helpers"
import { useMutation } from "@tanstack/react-query"
import { useSearchParams } from "next/navigation"
import { TrackJS } from "trackjs"

export const useCreateQuote = () => {
  const params = useSearchParams()
  const utmParams: Utms = {
    utm_source: params.get("utm_source") || "",
    utm_medium: params.get("utm_medium") || "",
    utm_campaign: params.get("utm_campaign") || "",
    utm_content: params.get("utm_content") || "",
    utm_term: params.get("utm_term") || "",
    utm_comparateur: params.get("utm_comparateur") || "",
    gclid: params.get("gclid") || "",
    compclid: params.get("compclid") || "",
    at_gd: params.get("at_gd") || "",
    advertiser_id: params.get("advertiserId") || "",
    channel_id: params.get("channelId") || "",
    market: params.get("market") || "",
    click_id: params.get("clickId") || "",
    click_ref: params.get("clickRef") || "",
    s_id: params.get("s_id") || "",
    affiliate_gclid: params.get("affiliate_gclid") || params.get("affiliateGclid") || "",
  }
  const referrer = params.get("utm_referrer") || ""
  return useMutation({
    mutationKey: ["create-quote"],
    mutationFn: async ({ quoteId, petIdx }: { quoteId?: string; petIdx?: number }) => {
      const quote = await createQuote(quoteId, petIdx)
      if (hasUtm(utmParams)) {
        await sendUtm(quote.data.quote_id, utmParams, referrer).catch((error: Error) => TrackJS.track(error))
      }
      return quote
    },
    retry: 0,
  })
}
