import { buildSyncBackBody } from "@/modules/Quote/hooks/use-sync-back"
import { syncBack } from "@/shared/http"
import type {
  Breed,
  Meta,
  Owner,
  OwnerAddress,
  Pet,
  Pricing,
  Utms,
} from "@/shared/types/global-store"
import { CreateQuoteResponse } from "@/shared/types/lead-api-types"
import { generatePetId } from "@/shared/utils/helpers"
import { assign } from "@xstate/immer"
import { TrackJS } from "trackjs"

import { clearState } from "./global-store"
import { SubscriptionFlowContext } from "./machine"

export type AddPetNameEvent = {
  type: "ADD_PET_NAME"
  input: {
    petName: string
  }
}

export type AddPetSexEvent = {
  type: "ADD_PET_SEX"
  input: {
    petSex: Pet["sex"]
  }
}

export type AddPetTypeEvent = {
  type: "ADD_PET_TYPE"
  input: {
    petType: Pet["type"]
  }
}

export type AddPetBreedEvent = {
  type: "ADD_PET_BREED"
  input: {
    petBreed: Breed
  }
}

export type AddPetBirthDateEvent = {
  type: "ADD_PET_BIRTH_DATE"
  input: {
    petBirthDate: string
  }
}

export type AddPetIdEvent = {
  type: "ADD_PET_ID"
  input: {
    petId: string
    petUuidType: Pet["uuid_type"]
  }
}

export type AddNewPetEvent = {
  type: "ADD_NEW_PET"
  input: undefined
}

export type UpdatePetEvent = {
  type: "UPDATE_PET"
  input: {
    petId: string
  }
}

export type DeletePetEvent = {
  type: "DELETE_PET"
  input: {
    petId: string
  }
}

export type ValidateTribeEvent = {
  type: "VALIDATE_TRIBE"
  input: undefined
}

export type AddOwnerNameEvent = {
  type: "ADD_OWNER_NAME"
  input: {
    firstname: string
    lastname: string
  }
}

export type AddOwnerMailAndPhoneEvent = {
  type: "ADD_OWNER_MAIL_AND_PHONE"
  input: {
    email: string
    phone: string
    firstname: string
    lastname: string
    prepackagesVariant: "A" | "B" | "default"
  }
}

export type CustomizeCoverageEvent = {
  type: "CUSTOMIZE_COVERAGE"
  input: {
    petId: string
  }
}

export type ChoosePrepackageEvent = {
  type: "CHOOSE_PREPACKAGE"
  input: {
    petId: string
    choosePrepackage: boolean
  }
}

export type StopLoadingPrepackageEvent = {
  type: "STOP_LOADING_PREPACKAGE"
}

export type AddPricingPlanEvent = {
  type: "ADD_PRICING_PLAN"
  input: {
    pricing: Pricing
  }
}

export type UpdatePricingPlanEvent = {
  type: "UPDATE_PRICING_PLAN"
  input: {
    pricing: Pricing
  }
}

export type ValidatePricingPlanEvent = {
  type: "VALIDATE_PRICING_PLAN"
  input: undefined
}

export type AddQuoteIdEvent = {
  type: "ADD_QUOTE_ID"
  data: CreateQuoteResponse
}

export type ValidatePetPlansEvent = {
  type: "VALIDATE_PET_PLANS"
  input: undefined
}

export type AddOwnerAddressEvent = {
  type: "ADD_OWNER_ADDRESS"
  input: OwnerAddress
}

export type AddOwnerBirthDateAndBirthCityEvent = {
  type: "ADD_OWNER_BIRTHDATE_AND_BIRTHCITY"
  input: {
    birthday: string
    birthcity: string
  }
}

export type GoToPriviousStateEvent = {
  type: "PREVIOUS"
  input: undefined
}

export type ResetMachineEvent = {
  type: "RESET_MACHINE"
  input: undefined
}

export type SetPriceEvent = {
  type: "SET_PRICE"
  input: {
    price: number
  }
}

export type AddAttributionQuestionEvent = {
  type: "ADD_ATTRIBUTION_QUESTION"
  input: {
    referrer_details: string
    referrer_type: string
  }
}

export type SetStepEvent = {
  type: "SET_STEP"
  input: number
}

export const addPetName = assign<SubscriptionFlowContext, AddPetNameEvent>((ctx, { input }) => {
  // If we have a petId, we are editing an existing pet
  if (ctx.currentPetId) {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    if (!pet) {
      // TODO: handle error. This should never happen
      return
    }

    pet.name = input.petName

    return
  }

  // If we don't have a petId, we are adding a new pet
  const newPetId = generatePetId()

  ctx.pets.push({
    name: input.petName,
    id: newPetId,
    idx: 0,
    isPetDraft: true,
    isQuoteCustomized: false,
  } as Pet)

  ctx.currentPetId = newPetId
})

export const addPetSex = assign<SubscriptionFlowContext, AddPetSexEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.sex = input.petSex
})

export const addPetType = assign<SubscriptionFlowContext, AddPetTypeEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.type = input.petType
})

export const addPetBreed = assign<SubscriptionFlowContext, AddPetBreedEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.breed = input.petBreed
})

export const addPetBirthDate = assign<SubscriptionFlowContext, AddPetBirthDateEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    pet.birthday = input.petBirthDate
  }
)

export const addPetId = assign<SubscriptionFlowContext, AddPetIdEvent>((ctx) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.uuid = "0000"
  pet.uuid_type = undefined
  pet.isPetDraft = false
})

export const addNewPet = assign<SubscriptionFlowContext, AddNewPetEvent>((ctx) => {
  // If we don't have a petId, we are adding a new pet
  const newPetId = generatePetId()

  const highestIdx = Math.max(...ctx.pets.map((pet) => pet.idx))

  ctx.pets.push({
    id: newPetId,
    idx: highestIdx + 1,
    isPetDraft: true,
    isQuoteCustomized: false,
  } as Pet)

  ctx.currentPetId = newPetId
})

export const updatePet = assign<SubscriptionFlowContext, UpdatePetEvent>((ctx, { input }) => {
  ctx.currentPetId = input.petId
})

export const deletePet = assign<SubscriptionFlowContext, DeletePetEvent>((ctx, { input }) => {
  ctx.pets = ctx.pets.filter((pet) => pet.id !== input.petId)
  ctx.currentPetId = ctx.pets[0]?.id
})

export const addOwnerName = assign<SubscriptionFlowContext, AddOwnerNameEvent>((ctx, { input }) => {
  ctx.owner.firstname = input.firstname
  ctx.owner.lastname = input.lastname
})

export const addOwnerMailAndPhone = assign<SubscriptionFlowContext, AddOwnerMailAndPhoneEvent>(
  (ctx, { input }) => {
    ctx.owner.email = input.email
    ctx.owner.phone = input.phone
    ctx.owner.firstname = input.firstname
    ctx.owner.lastname = input.lastname
    ctx.meta.ab_tests = { prepackages: { variant: input.prepackagesVariant } }
  }
)

export const customizeCoverage = assign<SubscriptionFlowContext, CustomizeCoverageEvent>(
  (ctx, { input }) => {
    ctx.currentPetId = input.petId
  }
)
export const choosePrepackage = assign<SubscriptionFlowContext, ChoosePrepackageEvent>(
  (ctx, { input }) => {
    ctx.currentPetId = input.petId
    const pet = ctx.pets.find((pet) => pet.id === input.petId)
    if (!pet) {
      return
    }
    pet.choosePrepackage = input.choosePrepackage
  }
)

export const addPricingPlan = assign<SubscriptionFlowContext, AddPricingPlanEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)
    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    pet.pricing = input.pricing
    pet.isQuoteCustomized = true
  }
)

export const updatePricingPlan = assign<SubscriptionFlowContext, AddPricingPlanEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    pet.pricing = input.pricing
  }
)

export const addQuoteId = assign<SubscriptionFlowContext, AddQuoteIdEvent>((ctx, event) => {
  TrackJS.addMetadata("quote_id", event.data.data.quote_id)
  ctx.meta.quote_id = event.data.data.quote_id

  if (event.data.data.quote_id === localStorage.getItem("quoteId")) {
    localStorage.removeItem("quoteId")
  }
})

export const addOwnerAddress = assign<SubscriptionFlowContext, AddOwnerAddressEvent>(
  (ctx, { input }) => {
    ctx.owner.address = input
  }
)

export const addOwnerBirthdayAndBirthCity = assign<
  SubscriptionFlowContext,
  AddOwnerBirthDateAndBirthCityEvent
>((ctx, { input }) => {
  ctx.owner.birthday = input.birthday
  ctx.owner.birthcity = input.birthcity
})

export const clearDraftPets = assign<SubscriptionFlowContext>((ctx) => {
  ctx.pets = ctx.pets.filter((pet) => !pet.isPetDraft)
})

export const resetMachine = assign<SubscriptionFlowContext>((ctx) => {
  clearState()

  ctx.pets = []
  ctx.owner = {} as Owner
  ctx.meta = {} as Meta
})

export const setLoadingPrepackage = assign<SubscriptionFlowContext>((ctx) => {
  return (ctx.setLoadingPrepackage = true)
})

export const stopLoadingPrepackage = assign<SubscriptionFlowContext>((ctx) => {
  return (ctx.setLoadingPrepackage = false)
})

export function matchHasValidPet(pets: Pet[]) {
  return pets.some((pet) => !pet.isPetDraft)
}

export function matchHasRedirectToPrepackages() {
  const forceRedirect = localStorage.getItem("force_redirect")
  return Boolean(forceRedirect && forceRedirect === "prepackages")
}

export function matchHasMultiplePets(pets: Pet[]) {
  return pets.filter((pet) => !pet.isPetDraft).length > 1
}

export const addAttributionQuestion = assign<SubscriptionFlowContext, AddAttributionQuestionEvent>(
  (ctx, { input }) => {
    ctx.marketing.referrer_details = input.referrer_details
    ctx.marketing.referrer_type = input.referrer_type
  }
)

export function syncBackHelper(ctx: SubscriptionFlowContext) {
  return syncBack(buildSyncBackBody(ctx))
}

export const setPrice = assign<SubscriptionFlowContext, SetPriceEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }
  pet.price = input.price
})

export const addPromoCode = assign<SubscriptionFlowContext>((ctx) => {
  const promoCode = localStorage.getItem("promoCode")
  if (promoCode) {
    ctx.meta.promo_code = promoCode
    localStorage.removeItem("promoCode")
  }
})

export const setStep = assign<SubscriptionFlowContext, SetStepEvent>((ctx, { input }) => {
  ctx.step = input
})

export const assignPrepackageVersionToB = assign<SubscriptionFlowContext>((context) => {
  context.prepackageVersion = "B"
})
