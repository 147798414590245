import { Utms } from "@/shared/types/global-store"
import type {
  CreateQuoteResponse,
  PricingResponse,
  SyncBackBody,
  ValidateQuoteData,
} from "@/shared/types/lead-api-types"
import { config } from "@/shared/utils/config"
import ky from "ky"

export const leadAPI = ky.create({
  prefixUrl: `https://lead.${config.API_DOMAIN}/${config.PRODUCT}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
})

/**
 * URL: `/quote`
 *
 * METHOD: `PUT`
 */
export function createQuote(quoteId = "", petIdx = 0) {
  return leadAPI
    .put("quote", {
      json: {
        step: "0",
        quote_id: quoteId,
        pet_idx: petIdx,
      },
    })
    .json<CreateQuoteResponse>()
}

export type QuoteReponse = {
  success: boolean
  data: PetQuote[]
  pet_owner: PetOwner
}

export type PetQuote = {
  accept_marketing_callback: boolean
  accept_quote: boolean
  address: Address
  api_version: string
  coverage?: { health_limit: string; prevention_limit: string; surgery_limit: string }
  insurer_cancellation: string
  options?: {
    health_rate: string
    pack: string
    prevention: boolean
    rate: string
    surgery_rate: string
  }
  optionsCard: any[]
  owner_birthcity: string
  owner_birthcountry: string
  owner_birthday: string
  owner_email: string
  owner_firstname: string
  owner_lastname: string
  owner_phone: string
  pet_age_month: number
  pet_age_year: number
  pet_birthday: string
  pet_category: string
  pet_father_race: string
  pet_health: string
  pet_idx: number
  pet_live_with_other: string
  pet_mother_race: string
  pet_name: string
  pet_old_diseases: string[]
  pet_race: string
  price: number | string
  original_price?: string
  pet_sexe: string
  pet_type: string
  pet_uuid_type: string
  pet_uuid: string
  pet_weight: string
  prevention_version: string
  product_dalma: string
  quote_id: string
  referrer: Referrer
  start_date: string
  subscription_start_at: string
  user_id: string
  utm_params: UtmParams
  versions: Versions
  ab_tests?: {
    prepackages: {
      variant: "A" | "B" | "default"
    }
  }
  marketing?: {
    referrer_details?: string
    referrer_type?: string
  }
}

export type UtmParams = {
  utm_term: string
  utm_comparateur: string
  gclid: string
  utm_campaign: string
  utm_medium: string
  utm_source: string
  utm_content: string
}

export type Referrer = {
  referrer_url: string
  referral: string
}

export type Address = {
  zipcode: string
  country: string
  street_nb: string
  city: string
  street_name: string
}

export type Versions = {}

export type PetOwner = {
  owner_email: string
  owner_lastname: string
  owner_firstname: string
  owner_birthcity: string
  owner_birthday: string
  address: Address2
  owner_phone: string
}

export type Address2 = {
  zipcode: string
  country: string
  street_nb: string
  city: string
  street_name: string
  country_code: "DE"
}

/**
 * URL: `/quote`
 *
 * METHOD: `GET`
 */
export function getQuote(quoteId: string) {
  return leadAPI
    .get("quote", {
      searchParams: {
        quote_id: quoteId,
      },
    })
    .json<QuoteReponse>()
}

/**
 * URL: `/utm`
 *
 * METHOD: `POST`
 */
export function sendUtm(quoteId: string, utmParams: Utms, referrer: string) {
  return leadAPI
    .post("utm", {
      json: {
        quote_id: quoteId,
        utm: utmParams,
        referrer: referrer,
      },
    })
    .json()
}

/**
 * URL: `/pet`
 *
 * METHOD: `DELETE`
 */
export function deletePet(quoteId: string, petIdx: number) {
  return leadAPI
    .delete("pet", {
      json: {
        data: {
          quote_id: quoteId,
          pet_idx: petIdx,
        },
      },
    })
    .json()
}

/**
 * URL: `/quote`
 *
 * METHOD: `POST`
 */
export function syncBack(data: SyncBackBody) {
  return leadAPI
    .post("quote", {
      json: {
        ...data,
      },
    })
    .json<{ success: boolean }>()
}

/**
 * URL: `/pricing`
 *
 * METHOD: `GET`
 */
export async function fetchPrices(quoteId: string, userId?: string) {
  return leadAPI
    .post("pricing", {
      json: {
        quote_id: quoteId,
        user_id: userId,
      },
    })
    .json<PricingResponse>()
}

/**
 * URL: `/quote-validation`
 *
 * METHOD: `POST`
 */
export async function validateQuote(data: ValidateQuoteData) {
  return leadAPI.post("quote-validation", { json: data }).json()
}
